import {Injectable} from "@angular/core";
import {AuthenticationService} from "./authentication.service";
import {ApiService} from "./api.service";
import {Observable} from "rxjs/Rx";

@Injectable()
export class AccountService {
    constructor(
        private api: ApiService,
        private authenticationService: AuthenticationService
    ) {}

    updateEmail(data) {
        return this.api.put("/pros/account/email", data);
    }

    updatePhone(phone) {
        return this.api.put("/pros/account/phone", {phone: phone});
    }

    createComingBackCronJob(leaveDate) {
        return this.api.post("/pros/account/leave", {leaveDate: leaveDate});
    }

    resetPassword(data) {
        return this.api.put("/pros/account/password", data);
    }

    update(user) {
        return this.api.put("/pros/account", user);
    }

    updateReferralGiftPreference(newReferralGift): Observable<any> {
      return this.api.put("/pros/account/referral/gift", {newReferralGift});
    }

    /**
     *
     * @param planInfo
     */
    startSubscription(cardToken, planInfo) {
        return this.api.post("/pros/account/subscription", {
            plan: planInfo,
            token: cardToken
        });
    }

    getCard() {
        return this.api.get("/pros/account/card");
    }

    updateCard(cardStatus) {
        return this.api.put("/pros/account/card", cardStatus);
    }

    /**
     *
     * @param id
     */

    getDevices(id) {
        return this.api.get("/pros/account/devices/proId/" + id);
    }

    sendAppLinkSms() {
        return this.api.get("/pros/account/sendAppLinkSms");
    }

    sendAppLinkSmsToProNotLogged(phone) {
        return this.api.post("/settings/send-app-link", {phone: phone});
    }

    updateOthersInformations(user) {
        return this.api.put("/pros/account/others-informations", user);
    }

    public uploadImage(image) {
        return this.api.post("/pros/account/avatar", image);
    }

    public updatePatientAccount(id, info) {
        return this.api.put(
            "/patients/account/patients/" + id + "/birth-info",
            info
        );
    }

    public isExistingPatient(patient) {
        return this.api.post("/pros/account/isExistingPatient", patient);
    }

    public getToken() {
        return this.api.get("/pros/account/getToken");
    }

    public signCharter() {
        return this.api.post("/pros/account/signCharter", null);
    }

    public refreshPro() {
        return this.api.get("/pros/account/refresh");
    }

    updateProLanguages(languages) {
        return this.api.post("/pros/account/languages", languages);
    }

    loadLanguages(): Observable<any> {
        return this.api.get("/pros/account/languages");
    }

    updatePromoCode(promoCode: string): Observable<any> {
        return this.api.post("/pros/account/promo-code", promoCode);
    }

    getSubscriptionInfos(): Observable<any> {
        return this.api.get("/pros/account/subscription-infos");
    }

    updateShareCount(socialMedium): Observable<any> {
        return this.api.post("/pros/account/sharing", {socialMedium});
    }

    getReferralData(): Observable<any> {
        return this.api.get("/pros/account/referral");
    }
}

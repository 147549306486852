import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {map, catchError} from "rxjs/operators";
import {Injectable} from "@angular/core";
import {AuthenticationService} from "./authentication.service";
import {Router} from "@angular/router";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(public auth: AuthenticationService, private router: Router) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const token = "JWT " + localStorage.getItem("accessToken");

        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: token
                }
            });
        }

        if (!request.headers.has("Content-Type")) {
            request = request.clone({
                setHeaders: {
                    "content-type": "application/json"
                }
            });
        }

        request = request.clone({
            headers: request.headers.set("Accept", "application/json")
        });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (
                    error.statusText === "Unauthorized" &&
                    error.status === 401
                ) {
                    localStorage.removeItem("accessToken");
                    this.router.navigate(["/auth/login"]);
                }
                return throwError(error);
            })
        );
    }
}

import {Injectable} from "@angular/core";
import Prismic from "prismic-javascript";
import * as PrismicDOM from "prismic-dom";
import {Document} from "prismic-javascript/d.ts/documents";
import ApiSearchResponse from "prismic-javascript/d.ts/ApiSearchResponse";

@Injectable({
    providedIn: "root"
})
export class PartnerOffersService {
    apiEndpoint = "https://libheros.cdn.prismic.io/api/v2";

    constructor() {}

    initApi() {
        return Prismic.getApi(this.apiEndpoint);
    }

    async getPartnerOffersFromPrismic(): Promise<Document[]> {
        const api = await this.initApi();
        const response: ApiSearchResponse = await api.query(
            Prismic.Predicates.at("document.type", "advertisement"),
            {}
        );
        return response.results;
    }
}

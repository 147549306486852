import {Component, ElementRef, ViewChild} from "@angular/core";
import {
    AccountService,
    AlertService,
    AuthenticationService
} from "../providers";
import {OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {Ng2ImgMaxService} from "ng2-img-max";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {User} from "../_models";
import {stringify} from "querystring";

@Component({
    selector: "app-upload-avatar",
    templateUrl: "./upload-avatar.component.html",
    styleUrls: ["./upload-avatar.component.css"]
})
export class UploadAvatarComponent implements OnInit {
    form: FormGroup;
    hide = false;
    uploadedImage: File;
    imagePreview: string;
    showClearFile: boolean;
    errors = false;
    loading = false;
    type: any;
    image = {
        filetype: null,
        value: null,
        size: null
    };
    imageChangedEvent: any = "";
    croppedImage: any = "";
    @ViewChild("fileInput", {static: true}) fileInput: ElementRef;

    constructor(
        private accountService: AccountService,
        private fb: FormBuilder,
        private alertService: AlertService,
        private authenticationService: AuthenticationService,
        private ng2ImgMax: Ng2ImgMaxService
    ) {}

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            avatar: null
        });
    }

    onFileChange(event) {
        if (event.target.files && event.target.files.length > 0) {
            this.showClearFile = true;
            this.errors = false;
            this.hide = true;
            this.imageChangedEvent = event;
        }
    }

    dataURItoBlob(dataURI) {
        let byteString;
        if (dataURI.split(",")[0].indexOf("base64") >= 0)
            byteString = atob(dataURI.split(",")[1]);
        else byteString = unescape(dataURI.split(",")[1]);

        // separate out the mime component
        const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.type = event.file.type;
        const blob = this.dataURItoBlob(this.croppedImage);
        const file = new File([blob], "avatar", {type: event.file.type});
        this.ng2ImgMax.compressImage(file, 0.075).subscribe(
            result => {
                this.uploadedImage = new File([result], result.name);
                this.getImagePreview(this.uploadedImage);
            },
            error => {}
        );
    }

    get f() {
        return this.form.controls;
    }

    getImagePreview(file: File) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.imagePreview = JSON.stringify(reader.result);
            this.form.get("avatar").setValue({
                value: this.imagePreview.split(",")[1],
                size: file.size,
                type: this.type
            });
        };
    }

    imageLoaded($event) {}

    loadImageFailed() {
        // show message
    }

    onSubmit() {
        if (this.form.value.avatar === null) {
            this.errors = true;
            return;
        }

        this.showClearFile = false;
        this.loading = true;
        const formModel = this.form.value;
        this.accountService.uploadImage(formModel).subscribe(
            pro => {
                this.loading = false;
                this.clearFile();
                this.authenticationService.userState.next(<User>pro);
            },
            error => {
                this.loading = false;
                this.clearFile();
                this.alertService.alert(
                    "warning",
                    "Une erreur est survenue, veuillez recommencer et si cela persiste, appelez-nous au 01 75 43 44 47  !"
                );
            }
        );
    }

    clearFile() {
        this.form.get("avatar").setValue(null);
        this.fileInput.nativeElement.value = "";
        this.hide = false;
    }
}

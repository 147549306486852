import {Component} from "@angular/core";
import {ActivatedRoute} from "@angular/router";

@Component({
    moduleId: module.id,
    selector: "error-cmp",
    templateUrl: "./error.component.html",
    styleUrls: ["./error.component.css"]
})
export class ErrorComponent {
    user;

    constructor(private route: ActivatedRoute) {
        this.route.data.subscribe(data => {
            this.user = data.pro;
        });
    }
}

import {
    Component,
    AfterViewInit,
    Output,
    EventEmitter,
    Input
} from "@angular/core";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PerfectScrollbarConfigInterface} from "ngx-perfect-scrollbar";
import {
    AuthenticationService,
    AssignationService,
    AlertsService
} from "../../providers";
import Swal from "sweetalert2";
import {Observable} from "rxjs";

declare var $: any;
declare let swal: any;

@Component({
    selector: "navbar-cmp",
    templateUrl: "./admin-navbar.component.html",
    styleUrls: ["./admin-navbar.component.css"]
})
export class NavigationComponent implements AfterViewInit {
    name: string;
    public badge = 0;
    public config: PerfectScrollbarConfigInterface = {};
    showMinisidebar: boolean;

    @Output()
    navBarCollapseClicked = new EventEmitter<boolean>();

    @Input()
    openSideBar: Observable<boolean>;

    constructor(
        private modalService: NgbModal,
        private authenticationService: AuthenticationService,
        private assignationService: AssignationService,
        private alertsService: AlertsService
    ) {
        this.getBadgeNumber();
    }

    getBadgeNumber() {
        this.alertsService.listUnreadAlerts().subscribe(
            nbUnreadAlerts => {
                this.badge = nbUnreadAlerts.count;
            },
            err => {}
        );
    }

    ngAfterViewInit() {
        var set = function () {
            var width =
                window.innerWidth > 0 ? window.innerWidth : this.screen.width;
            var topOffset = 0;
            if (width < 1170) {
                $("#main-wrapper").addClass("mini-sidebar");
            } else {
                $("#main-wrapper").removeClass("mini-sidebar");
            }
        };
        $(window).ready(set);
        $(window).on("resize", set);

        $(".search-box a, .search-box .app-search .srh-btn").on(
            "click",
            function () {
                $(".app-search").toggle(200);
            }
        );

        $("body").trigger("resize");

        this.openSideBar.subscribe((showMiniSideBar: boolean) => {
            this.showMinisidebar = showMiniSideBar;
        });

        this.showMinisidebar = window.innerWidth <= 768;
    }

    logout() {
        return this.authenticationService.logout().subscribe();
    }

    showHelpModal() {
        Swal({
            title: "Besoin d'aide ?",
            html: `<p>
                        <a href="mailto:support@libheros.fr?subject=Question depuis l'interface web libheros PRO">
                            <i class="mdi mdi-email"></i> support@libheros.fr
                        </a>
                    </p>
                    <p>
                        <a href="tel:0175434447">
                            <i class="mdi mdi-phone"></i> 01 75 43 44 47
                        </a>
                    </p>
                    <p>
                        <a href="https://libheros.zendesk.com/hc/fr/categories/360001200520-Professionnels-de-sant%C3%A9" target="_blank">
                            <i class="mdi mdi-help-circle"></i> Questions fréquentes
                        </a>
                    </p>`,
            type: "info",
            showCloseButton: true
        });
    }

    updateSideBar() {
        this.showMinisidebar = !this.showMinisidebar;
        this.navBarCollapseClicked.emit(this.showMinisidebar);
    }
}

import {Pipe, PipeTransform} from "@angular/core";
import * as moment from "moment";

@Pipe({
    name: "imageDate"
})
export class DatePipe implements PipeTransform {
    transform(date: any, args?: any): any {
        moment.locale("FR");

        if (!date) {
            return "-";
        }
        date = moment(date);
        if (!date.isValid()) {
            return "-";
        }

        if (args === "full") {
            return date.format("dddd DD MMMM à HH[h]mm");
        } else {
            return "NotFormat";
        }
    }
}

import {Routes} from "@angular/router";
import {FullComponent} from "./layouts/full/full.component";
import {BlankComponent} from "./layouts/blank/blank.component";
import {NotFoundComponent} from "./shared/404/404.component";
import {ErrorComponent} from "./shared/error/error.component";
import {PartnerOffersComponent} from "./partner-offers/partner-offers.component";
import {SponsorshipComponent} from "./sponsorship/sponsorship.component";
import {MedicationReblozylComponent} from "./medication-reblozyl/medication-reblozyl.component";
import {AdminGuard, ProResolverService} from "./providers";
import {MaintenanceComponent} from "./maintenance/maintenance.component";

export const Approutes: Routes = [
    {
        path: "",
        canActivate: [AdminGuard],
        component: FullComponent,
        children: [
            {path: "", redirectTo: "/dashboard", pathMatch: "full"},
            {
                path: "dashboard",
                resolve: {pro: ProResolverService},
                loadChildren: "./dashboard/dashboard.module#DashboardModule"
            },
            {
                path: "missions",
                resolve: {pro: ProResolverService},
                loadChildren: () =>
                    import(
                        "./missions-assignations/missions-assignations.module"
                    ).then(m => m.MissionsAssignationsModule)
            },
            {
                path: "rendez-vous",
                resolve: {pro: ProResolverService},
                loadChildren: () =>
                    import("./assignations/assignations.module").then(
                        m => m.AssignationsModule
                    )
            },
            {
                path: "settings",
                resolve: {pro: ProResolverService},
                loadChildren: () =>
                    import("./settings/settings.module").then(
                        m => m.SettingsModule
                    )
            },
            {
                path: "profile",
                resolve: {pro: ProResolverService},
                loadChildren: () =>
                    import("./profile/profile.module").then(
                        m => m.ProfileModule
                    )
            },
            {
                path: "offres-partenaires",
                resolve: {pro: ProResolverService},
                component: PartnerOffersComponent,
                data: {title: "Offres de nos partenaires"}
            },
            {
                path: "sponsorship",
                resolve: {pro: ProResolverService},
                component: SponsorshipComponent,
                data: {title: "Parrainage"}
            },
            {
                path: "reblozyl",
                resolve: {pro: ProResolverService},
                component: MedicationReblozylComponent,
                data: {
                    title: "REBLOZYL (luspatercept) ",
                    alternative_title:
                        "du laboratoire Bristol Myers Squibb",
                    sub_title: "Communication promotionnelle"
                }
            },
          {
            path: "maintenance",
            resolve: {pro: ProResolverService},
            component: MaintenanceComponent,
            data: {
              title: "Maintenance"
            }
          },
            {
                path: "alerts",
                resolve: {pro: ProResolverService},
                loadChildren: () =>
                    import("./alerts/alerts.module").then(m => m.AlertsModule)
            }
        ]
    },
    {
        path: "",
        component: BlankComponent,
        children: [
            {path: "", redirectTo: "/auth/login", pathMatch: "full"},
            {
                path: "auth",
                loadChildren: () =>
                    import("./authentication/authentication.module").then(
                        m => m.AuthenticationModule
                    )
            },
            {
                path: "christmas",
                loadChildren: () =>
                    import("./christmas/christmas.module").then(
                        m => m.christmasModule
                    )
            }
        ]
    },
    {
        path: "bienvenue",
        loadChildren: () =>
            import("./welcolme/welcome.module").then(m => m.welcomeModule)
    },
    {
        path: "error",
        resolve: {pro: ProResolverService},
        component: ErrorComponent
    },
    {path: "404", component: NotFoundComponent},
    {path: "**", redirectTo: "404"}
];

import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Observable, of, EMPTY} from "rxjs";
import {mergeMap, take} from "rxjs/operators";
import {AccountService} from "./account.service";
import {AuthenticationService} from "./authentication.service";

@Injectable({
    providedIn: "root"
})
export class ProResolverService {
    constructor(
        private accountService: AccountService,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    resolve(): Observable<any> {
        return this.accountService.refreshPro().pipe(
            take(1),
            mergeMap(pro => {
                if (pro) {
                    this.authenticationService.userState.next(pro);
                    return of(pro);
                } else {
                    this.router.navigate(["/dashboard"]);
                    return EMPTY;
                }
            })
        );
    }
}

import {Component, Input, OnInit} from "@angular/core";

declare const $: any;

@Component({
    selector: "medication-reblozyl",
    templateUrl: "./medication-reblozyl.component.html",
    styleUrls: ["./medication-reblozyl.component.scss"]
})
export class MedicationReblozylComponent implements OnInit {
    pdfLink = "https://csb3f4de42cd3fax4b41xa29.blob.core.windows.net/pdf/Guide de dispensation, de reconstitution et d'administration de Reblozyl.pdf#view=FitH";
    constructor() {}

    ngOnInit() {
        $("#main-wrapper").addClass("mini-sidebar");
        $("body").trigger("resize");
    }
}

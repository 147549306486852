import {Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {CanActivate} from "@angular/router";

@Injectable()
export class StepGuard implements CanActivate {
    validated = null;
    config_step = null;

    constructor(private router: Router) {}

    canActivate() {
        console.log("currentUser");
        if (localStorage.getItem("currentUser")) {
            this.validated = JSON.parse(
                localStorage.getItem("currentUser")
            ).validated;
            this.config_step = JSON.parse(
                localStorage.getItem("currentUser")
            ).config_step;
            if (this.config_step === "ONGOING") {
                return true;
            }
            return !(this.config_step === "OK" && this.validated);
        }

        this.router.navigate(["/auth/login"]);
        return false;
    }
}

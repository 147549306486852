import {Pipe, PipeTransform} from "@angular/core";
import * as moment from "moment";

@Pipe({
    name: "momentTime"
})
export class MomentTimePipe implements PipeTransform {
    transform(time: any, args?: any): any {
        moment.locale("FR");

        if (!time) {
            return "-";
        }

        time = moment(time, "HH:mm:ss");
        if (!time.isValid()) {
            return "-";
        }

        if (args === "hours") {
            return time.format("HH");
        }

        if (args === "minutes") {
            return time.format("mm");
        }

        return "NotFormat";
    }
}

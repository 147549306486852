import {Injectable} from "@angular/core";

declare let swal: any;

@Injectable({
    providedIn: "root"
})
export class TeamNameService {
    constructor() {}

    checktTeamName(jobId: number, teamName: string): any {
        teamName = this.updateNameWithUpperCase(teamName);
        return this.checkTeamNameValidation(jobId, teamName);
    }

    updateNameWithUpperCase(name: string): string {
        name = name.toLowerCase();
        let nameArray: Array<string> = name.split(" ");
        nameArray = nameArray.map((word: string, index: number) => {
            if (index === 0 && word.startsWith("de")) {
                return word;
            }
            if (word.includes("-")) {
                const newWordArray = word.split("-");
                return newWordArray
                    .map(
                        (word: string) =>
                            word[0] && word[0].toUpperCase() + word.slice(1)
                    )
                    .join("-");
            } else if (word.includes("'")) {
                const newWordArray = word.split("'");
                return newWordArray
                    .map(
                        (word: string) =>
                            word[0] && word[0].toUpperCase() + word.slice(1)
                    )
                    .join("'");
            } else {
                return word[0] && word[0].toUpperCase() + word.slice(1);
            }
        });
        return nameArray.join(" ");
    }

    checkTeamNameValidation(jobId: number, teamName: string): string | null {
        let officeName =
            jobId !== 4 ? `Cabinet ${teamName}` : `Laboratoire ${teamName}`;
        let testOfficeName = officeName;
        const regex = /é|de|-|d'|w*s\b/gim;
        testOfficeName = testOfficeName.replace(regex, (p1: string) => {
            let result: string;
            switch (p1) {
                case "é":
                    result = "e";
                    break;

                case "de" || "d'" || "s":
                    result = "";
                    break;

                case "-":
                    result = " ";
                    break;

                default:
                    result = "";
            }
            return result;
        });
        testOfficeName = testOfficeName.replace(/  /, " ");
        const forbiddenName = [
            "cabinet infirmier",
            "cabinet kinesitherapie",
            "cabinet sage femme",
            "laboratoire",
            "laboratoire analyse",
            "laboratoire biologie medicale"
        ];

        if (forbiddenName.includes(testOfficeName.toLowerCase())) {
            this.showInfoName(jobId);
            return null;
        } else {
            return officeName.split(" ").slice(1).join(" ");
        }
    }

    showInfoName(jobId: number) {
        let forbiddenName: string;
        const job = jobId === 4 ? "Laboratoire" : "Cabinet";
        const li = `<li>Commence forcément par "${job}";</li>
                <li>Ne doit pas être un nom fantaisiste ou sonnant comme de la publicité;</li>
                <li>Sachez que les ${job.toLocaleLowerCase()}s ne sont jamais affichés par ordre alphabétique mais toujours aléatoirement;</li>`;

        switch (jobId) {
            case 1:
                forbiddenName = `"Cabinet infirmier""`;
                break;

            case 2:
                forbiddenName = `"Cabinet de kinésithérapie"`;
                break;

            case 3:
                forbiddenName = `"Cabinet de sage-femme"`;
                break;

            default:
                forbiddenName = `"Laboratoire d'analyses" ou "Laboratoire de biologie médicale"`;
        }
        const msg = {
            title: `Votre nom de ${jobId === 4 ? "laboratoire" : "cabinet"}`,
            html: `
            <div>
                <ul style="text-align: left;">
                    ${li}
                    <li>Ne doit pas seulement être "${job}" ou ${forbiddenName};</li>
                </ul>
            </div>
        `
        };
        swal(msg);
    }

    updateGenericTeamName(jobId: number): string {
        return jobId !== 4 ? "Cabinet" : "Laboratoire";
    }
}

import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {AuthFooter} from "./auth-footer.component";

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [AuthFooter],
    exports: [AuthFooter]
})
export class authFooterModule {}

import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {Observable} from "rxjs/Rx";

@Injectable()
export class PatientService {
    constructor(private api: ApiService) {}

    getPatients(): Observable<any> {
        return this.api.get("/pros/account/patients");
    }
}

import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";
import {ApiService} from "./api.service";
import {tap, catchError} from "rxjs/operators";

@Injectable()
export class AuthenticationService {
    private authenticationState = new BehaviorSubject(null);
    public userState = new BehaviorSubject(null);
    user = this.userState.asObservable();

    constructor(private api: ApiService, private router: Router) {}

    signin(credentials) {
        return this.api.post("/pros/auth/login", credentials).map(
            response => {
                localStorage.setItem("email_user", credentials.email);
                localStorage.setItem("accessToken", response["token"]);
                this.authenticationState.next({id: response["id"]});
                return response;
            },
            error => {
                console.log("error", error);
            }
        );
    }

    signup(user: any) {
        return this.api
            .post("/pros/mobile/auth/register", user)
            .map((response: Response) => {
                localStorage.setItem("email_user", user.email);
                localStorage.setItem("accessToken", response["token"]);
                this.authenticationState.next({
                    id: response["id"],
                    team_id: response["team_id"]
                });
            });
    }

    signupLight(user: any) {
        return this.api
            .post("/pros/mobile/auth/register/light", user)
            .map((response: Response) => {
                localStorage.setItem("email_user", user.email);
                localStorage.setItem("accessToken", response["token"]);
                this.authenticationState.next({id: response["id"]});
            });
    }

    addinviteRecommendedColleague(user: any) {
        return this.api
            .put("/pros/auth/register/invite-colleague", user)
            .map((response: Response) => {
                localStorage.setItem("accessToken", response["token"]);
                this.authenticationState.next({id: response["user"]["id"]});
                this.userState.next(response["user"]);
            });
    }

    static getAccessToken() {
        return localStorage.getItem("accessToken");
    }

    isAuthenticated(): boolean {
        return !!AuthenticationService.getAccessToken();
    }

    signCharter() {
        return this.api.post("/pros/account/signCharter", {}).map(response => {
            return response;
        });
    }

    logout() {
        return this.api.delete("/pros/auth/logout").pipe(
            tap(async () => {
                localStorage.removeItem("accessToken");
                this.authenticationState.next(false);
                return this.router.navigate(["/auth/login"]);
            }),
            catchError(error => {
                const msg = error.error.message;
                throw new Error(msg);
            })
        );
    }

    resetPassword(email) {
        return this.api
            .put("/pros/auth/reset", {email: email})
            .map((response: Response) => {
                return response;
            });
    }

    // getHomeURL() {
    //   if (!this.authenticationState) {
    //     return '/auth/login';
    //   }
    //   switch (this.currentUser.config_step) {
    //     case 'OK':
    //       return '/dashboard';
    //     case 'ONGOING':
    //       return '/auth/step/office';
    //     default:
    //       return '/auth/login';
    //   }
    // }
}

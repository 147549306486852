import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot
} from "@angular/router";
import {Injectable} from "@angular/core";
import {CanActivate} from "@angular/router";
import {Location} from "@angular/common";

@Injectable()
export class AdminGuard implements CanActivate {
    validated = null;
    config_step = null;

    constructor(private router: Router, private location: Location) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if (
          state.url.includes("reblozyl") &&
          !localStorage.getItem("accessToken")
      ) {
          localStorage.setItem("isRedirectToReblozyl", "true");
      }

        if (localStorage.getItem("accessToken")) {
            return true;
        }
        localStorage.setItem("requestedURL", state.url);
        this.router.navigate(["/auth/login"]);
        return false;
    }
}

export const environment = {
    origin: "https://api.lib-heros.com",
    // origin: 'https://api.libherosbeta.fr',
    //origin: 'http://api-sammy.libherosbeta.fr',
    //origin: 'https://api.lib-heros.com',
    //origin: 'https://api.lib-heros.com',
    //origin: 'https://api.lib-heros.fr',
    // origin: "http://localhost:1234",
    stripePublishableKey: "pk_test_NVleaRrSc3xCxXOmBtuINlyI",
    google_api_key: "AIzaSyCHlrYPxfgla0yifIZE7eVgVtaRa4AbpFk",
    production: false,
    domaineName: "MEDECIN1",
    domaineCode: "LGC1",
    domaineType: "M",
    contextUrl: "https://testpro.sante-idf.fr/AutoConnectSSO/LPSRelay ",
    youSignUrl: "https://staging-app.yousign.com",
    youSignToken: "e99154c6-c725-4438-9bc9-fc641c4bb657",
    flowUrl: "https://flow.libheros.fr",
    version: "2.0.1"
};

import {Component, Input} from "@angular/core";
import * as moment from "moment";

@Component({
    selector: "frame-time-picker",
    templateUrl: "./frame-time-picker.component.html",
    styleUrls: ["./frame-time-picker.component.css"]
})
export class FrameTimePickerComponent {
    @Input() frame;
    @Input() period: string;
    private hourStep = 1;
    private minuteStep = 15;

    constructor() {}

    hoursUp() {
        this.plusHours(this.hourStep);
    }

    hoursDown() {
        this.plusHours(-this.hourStep);
    }

    minutesUp() {
        this.plusMinutes(this.minuteStep);
    }

    minutesDown() {
        this.plusMinutes(-this.minuteStep);
    }

    private plusHours(hours) {
        this.plusMinutes(60 * hours);
    }

    private plusMinutes(minutes) {
        const newFrame = this.createNewFrameWithAddedMinutes(minutes);
        if (!this.isValid(newFrame)) {
            return;
        }

        this.frame.start = newFrame.start;
        this.frame.end = newFrame.end;
    }

    private createNewFrameWithAddedMinutes(minutes) {
        const momentFrame = {
            start: moment(this.frame.start, "HH:mm"),
            end: moment(this.frame.end, "HH:mm")
        };
        momentFrame[this.period].add(minutes, "minutes");
        return {
            start: momentFrame.start.format("HH:mm"),
            end: momentFrame.end.format("HH:mm")
        };
    }

    private isValid(frame) {
        return frame.start < frame.end;
    }
}

import {Component, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {AlertService, TeamService} from "../../providers";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {forkJoin} from "rxjs/observable/forkJoin";

@Component({
    selector: "frames-cmp",
    templateUrl: "./edit-frames.component.html",
    styleUrls: ["./edit-frames.component.scss"]
})
export class EditFramesComponent implements OnInit {
    step = false;
    visitHours = [];
    addFrameButton: boolean = false;
    deleteFrameButton: boolean = false;
    updateFrameButton: boolean = false;

    constructor(
        private router: Router,
        private teamService: TeamService,
        private alertService: AlertService,
        private location: Location
    ) {
        this.step = this.location.path() === "/step/frames";
    }

    ngOnInit() {
        this.getFrames();
    }

    addFrame() {
        if (this.visitHours.length < 5) {
            this.visitHours.push({start: "08:00", end: "20:00"});
        } else {
            this.addFrameButton = true;
        }
    }

    deleteFrame(frame) {
        if (this.visitHours.length == 1) {
            this.deleteFrameButton = true;
            return this.alertService.alert(
                "warning",
                "Veuillez définir au moins un créneau de disponibilité"
            );
        }
        const index = this.visitHours.indexOf(frame);
        if (index > -1) {
            this.visitHours.splice(index, 1);
        }
    }

    updateFrames() {
        let visitHoursConcatenated = "";
        this.visitHours.forEach((visitHour, index) => {
            visitHoursConcatenated += visitHour.start + " - " + visitHour.end;
            if (index !== this.visitHours.length - 1) {
                visitHoursConcatenated += ",";
            }
        });
        this.teamService.updateFrames(visitHoursConcatenated).subscribe(
            () => {
                this.updateFrameButton = true;
                this.alertService.alert(
                    "success",
                    "Vos horaires ont bien été mis à jour!"
                );
            },
            err => {
                this.alertService.alert(
                    "warning",
                    "Une erreur est survenue, veuillez réessayer."
                );
                console.log(err);
            }
        );
    }

    private getFrames() {
        return this.teamService.getVisitHours().subscribe(
            frames => {
                this.visitHours = frames;
            },
            err => {
                console.log(err);
            }
        );
    }
}

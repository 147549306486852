import {Injectable} from "@angular/core";
import {AuthenticationService} from "./authentication.service";
import {ApiService} from "./api.service";
import {Observable} from "rxjs/Rx";

@Injectable()
export class FileService {
    constructor(private api: ApiService, private auth: AuthenticationService) {}

    getFiles(): Observable<any> {
        return this.api.get("/pros/account/files");
    }

    getFile(id): Observable<any> {
        return this.api.get("/pros/account/files/" + id);
    }

    deleteFile(id): Observable<any> {
        return this.api.delete("/pros/account/files/" + id);
    }

    updateFile(newFields, id): Observable<any> {
        return this.api.put("/pros/account/files/" + id, newFields);
    }
}

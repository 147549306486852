import Debug from "debug";
import * as Prismic from "prismic-javascript";
import * as PrismicDOM from "prismic-dom";
const apiEndpoint = "https://libheros.cdn.prismic.io/api/v2";
const debug = Debug("app:session");

export const getCharterFromPrismic = async () => {
    debug("getCharterFromPrismic");
    try {
        let api = await initApi();
        let response = await api.query(
            Prismic.Predicates.at("document.type", "charter"),
            {pageSize: 30}
        );

        let html = serialize(response);
        return html;
    } catch (err) {
        throw err;
    }
};

function initApi() {
    return Prismic.getApi(apiEndpoint);
}

function serialize(document) {
    const doc =
        PrismicDOM.RichText.asHtml(document.results[0].data.summary) +
        PrismicDOM.RichText.asHtml(document.results[0].data.body);
    return doc;
}

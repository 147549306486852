import {Component, Input, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {AlertService, AccountService} from "../providers";
import {environment} from "../../environments/environment";

@Component({
    selector: "sponsorship",
    templateUrl: "./sponsorship.component.html",
    styleUrls: ["./sponsorship.component.scss"]
})
export class SponsorshipComponent implements OnInit {
    user: any;
    url: string;
    email: string;
    mobile: boolean = false;
    giftType: string;
    referrees = [];
    validatedReferrees = [];
    loadingLevel: number;
    status: "low" | "medium" | "high" | "max" = "low";
    missingReferrals: number = 5;
    title: string;
    nextTitle: string;
    badge: string;
    constructor(
        private alertService: AlertService,
        private route: ActivatedRoute,
        private accountService: AccountService
    ) {
        this.route.data.subscribe(data => {
            this.user = data.pro;
            this.url = environment.production
                ? `pro.libheros.fr/auth/register?ref=${this.user.url_identifier}`
                : `pro.lib-heros.fr/auth/register?ref=${this.user.url_identifier}`;
        });
    }

    ngOnInit() {
        if (window.screen.width < 1024) {
            this.mobile = true;
        }
        this.accountService.getReferralData().subscribe(data => {
            this.referrees = data.referrees;
            this.giftType = data.giftType;
            this.assessReferralLevel();
        });
    }

    assessReferralLevel() {
        this.validatedReferrees = this.referrees.filter(
            referree => referree.referreeVisibility === "AVAILABLE"
        );
        if (
            this.validatedReferrees.length >= 0 &&
            this.validatedReferrees.length <= 5
        ) {
            this.status = "medium";
            this.loadingLevel = Math.floor(
                (this.validatedReferrees.length / 5) * 100
            );
            this.missingReferrals = 5 - this.validatedReferrees.length;
            this.title = "Apprenti héros";
            this.nextTitle = "Héros";
            this.badge = "referral_1";
        } else if (
            this.validatedReferrees.length > 5 &&
            this.validatedReferrees.length <= 10
        ) {
            this.status = "high";
            this.loadingLevel = Math.floor(
                (this.validatedReferrees.length / 10) * 100
            );
            this.missingReferrals = 10 - this.validatedReferrees.length;
            this.title = "Héros";
            this.nextTitle = "Super Héros";
            this.badge = "referral_2";
        } else if (this.validatedReferrees.length > 10) {
            this.status = "max";
            this.loadingLevel =
                Math.floor((this.validatedReferrees.length / 11) * 100) > 100
                    ? 100
                    : Math.floor((this.validatedReferrees.length / 11) * 100);
            this.missingReferrals = null;
            this.title = "Super Héros";
            this.badge = "referral_3";
        }
    }

    click() {
        this.countShare("copy");
        this.alertService.alert("success", "Lien copié dans le presse papier!");
    }

    onKey(e) {
        this.email = e.target.value;
    }
    openFacebook() {
        this.countShare("facebook");
        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${this.url}`,
            "",
            "left=20,top=20,width=500,height=500,toolbar=1,resizable=0"
        );
    }

    openWhatsapp() {
        this.countShare("whatsapp");
        const message = `
      Chers(res) collègues,
      je vous partage aujourd’hui le bon plan libheros PRO. L’application rend mon cabinet visible auprès des patients présents dans ma zone de tournée. Le site propose également des missions rémunérées en entreprise (test sérologique/virologique, formation aux gestes barrières, etc). L’inscription est gratuite, inscrivez-vous depuis ce lien : ${this.url}`;
        window.open(`https://api.whatsapp.com/send?text=${message}`);
    }

    assignGift(gift) {
      this.giftType = gift;
      this.accountService.updateReferralGiftPreference(gift).subscribe();
      this.alertService.alert(
        "success",
        "Votre préférence a bien été prise en compte, notre équipe vous contactera rapidement !"
      );
    }

    countShare(socialMedium) {
        this.accountService.updateShareCount(socialMedium).subscribe();
    }
}

import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {Observable, Subject} from "rxjs";

@Injectable()
export class AssignationService {
    constructor(private api: ApiService) {}

    getActiveAndForthcomingAssignations() {
        return this.api.get(
            "/pros/account/assignations/active_and_forthcoming"
        );
    }

    getArchivedAssignations() {
        return this.api.get("/pros/account/assignations/archived");
    }

    getAssignationById(id: number) {
        return this.api.get("/pros/account/assignations/" + id);
    }

    accept(id: number): Observable<any> {
        return this.api.put(
            "/pros/account/assignations/" + id + "/accepted",
            null
        );
    }

    cancelAndUpdateProComments(
        id: number,
        {cancelReason, awayDates}
    ): Observable<any> {
        return this.api.put("/pros/account/assignations/" + id + "/cancelled", {
            cancelReason,
            awayDates
        });
    }

    updateProComments(id: number, pro_comments: any) {
        return this.api.put(
            "/pros/account/assignations/" + id + "/pro_comments",
            {pro_comments: pro_comments}
        );
    }

    getNbOpenAndForthcomingAssignations(): Observable<any> {
        return this.api.get(
            "/pros/account/assignations/open_and_forthcoming/nb"
        ) as Observable<any>;
    }

    updateAstraResult(astraResult: boolean, assignationId: number) {
        return this.api.put(
            "/pros/account/assignations/" + assignationId + "/astra-result",
            {astraResult}
        );
    }
}

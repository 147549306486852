import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {MomentDatePipe} from "../pipes/moment-date.pipe";
import {MomentTimePipe} from "../pipes/moment-time.pipe";
import {FrameTimePickerComponent} from "./frame-time-picker/frame-time-picker.component";
import {SidebarModule} from "./sidebar/sidebar.module";
import {EditFramesComponent} from "../settings/edit-frames/edit-frames.component";
@NgModule({
    imports: [CommonModule, SidebarModule],
    exports: [
        CommonModule,
        FormsModule,
        FrameTimePickerComponent,
        MomentTimePipe,
        MomentDatePipe
    ],
    declarations: [
        FrameTimePickerComponent,
        MomentTimePipe,
        MomentDatePipe,
        EditFramesComponent
    ]
})
export class SharedModule {}

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {UploadAvatarComponent} from "./upload-avatar.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Ng2ImgMaxModule} from "ng2-img-max";
import {ImageCropperModule} from "ngx-image-cropper";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        NgbModule,
        Ng2ImgMaxModule,
        ImageCropperModule
    ],
    exports: [UploadAvatarComponent],
    declarations: [UploadAvatarComponent],
    providers: []
})
export class UploadAvatarModule {}

import {Injectable} from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class DataService {
    constructor() {}

    getCountries() {
        const countries = [
            {
                id: 0,
                insee: 99000,
                name: "FRANCE",
                iso_2: "FR",
                iso_3: "FRA",
                iso_num: 250
            },
            {
                id: 1,
                insee: 99212,
                name: "AFGHANISTAN",
                iso_2: "AF",
                iso_3: "AFG",
                iso_num: 4
            },
            {
                id: 2,
                insee: 99303,
                name: "AFRIQUE DU SUD",
                iso_2: "ZA",
                iso_3: "ZAF",
                iso_num: 710
            },
            {
                id: 3,
                insee: 99125,
                name: "ALBANIE",
                iso_2: "AL",
                iso_3: "ALB",
                iso_num: 8
            },
            {
                id: 4,
                insee: 99352,
                name: "ALGERIE",
                iso_2: "DZ",
                iso_3: "DZA",
                iso_num: 12
            },
            {
                id: 5,
                insee: 99109,
                name: "ALLEMAGNE",
                iso_2: "DE",
                iso_3: "DEU",
                iso_num: 276
            },
            {
                id: 6,
                insee: 99130,
                name: "ANDORRE",
                iso_2: "AD",
                iso_3: "AND",
                iso_num: 20
            },
            {
                id: 7,
                insee: 99395,
                name: "ANGOLA",
                iso_2: "AO",
                iso_3: "AGO",
                iso_num: 24
            },
            {
                id: 8,
                insee: 99425,
                name: "ANGUILLA",
                iso_2: "AI",
                iso_3: "AIA",
                iso_num: 660
            },
            {
                id: 9,
                insee: 99441,
                name: "ANTIGUA-ET-BARBUDA",
                iso_2: "AG",
                iso_3: "ATG",
                iso_num: 28
            },
            {
                id: 10,
                insee: 99201,
                name: "ARABIE SAOUDITE",
                iso_2: "SA",
                iso_3: "SAU",
                iso_num: 682
            },
            {
                id: 11,
                insee: 99415,
                name: "ARGENTINE",
                iso_2: "AR",
                iso_3: "ARG",
                iso_num: 32
            },
            {
                id: 12,
                insee: 99252,
                name: "ARMENIE",
                iso_2: "AM",
                iso_3: "ARM",
                iso_num: 51
            },
            {
                id: 13,
                insee: 99501,
                name: "AUSTRALIE",
                iso_2: "AU",
                iso_3: "AUS",
                iso_num: 36
            },
            {
                id: 14,
                insee: 99110,
                name: "AUTRICHE",
                iso_2: "AT",
                iso_3: "AUT",
                iso_num: 40
            },
            {
                id: 15,
                insee: 99253,
                name: "AZERBAIDJAN",
                iso_2: "AZ",
                iso_3: "AZE",
                iso_num: 31
            },
            {
                id: 16,
                insee: 99436,
                name: "BAHAMAS",
                iso_2: "BS",
                iso_3: "BHS",
                iso_num: 44
            },
            {
                id: 17,
                insee: 99249,
                name: "BAHREIN",
                iso_2: "BH",
                iso_3: "BHR",
                iso_num: 48
            },
            {
                id: 18,
                insee: 99246,
                name: "BANGLADESH",
                iso_2: "BD",
                iso_3: "BGD",
                iso_num: 50
            },
            {
                id: 19,
                insee: 99434,
                name: "BARBADE",
                iso_2: "BB",
                iso_3: "BRB",
                iso_num: 52
            },
            {
                id: 20,
                insee: 99131,
                name: "BELGIQUE",
                iso_2: "BE",
                iso_3: "BEL",
                iso_num: 56
            },
            {
                id: 21,
                insee: 99429,
                name: "BELIZE",
                iso_2: "BZ",
                iso_3: "BLZ",
                iso_num: 84
            },
            {
                id: 22,
                insee: 99327,
                name: "BENIN",
                iso_2: "BJ",
                iso_3: "BEN",
                iso_num: 204
            },
            {
                id: 23,
                insee: 99425,
                name: "BERMUDES",
                iso_2: "BM",
                iso_3: "BMU",
                iso_num: 60
            },
            {
                id: 24,
                insee: 99214,
                name: "BHOUTAN",
                iso_2: "BT",
                iso_3: "BTN",
                iso_num: 64
            },
            {
                id: 25,
                insee: 99148,
                name: "BIELORUSSIE",
                iso_2: "BY",
                iso_3: "BLR",
                iso_num: 112
            },
            {
                id: 26,
                insee: 99418,
                name: "BOLIVIE",
                iso_2: "BO",
                iso_3: "BOL",
                iso_num: 68
            },
            {
                id: 27,
                insee: 99118,
                name: "BOSNIE-HERZEGOVINE",
                iso_2: "BA",
                iso_3: "BIH",
                iso_num: 70
            },
            {
                id: 28,
                insee: 99347,
                name: "BOTSWANA",
                iso_2: "BW",
                iso_3: "BWA",
                iso_num: 72
            },
            {
                id: 29,
                insee: 99416,
                name: "BRESIL",
                iso_2: "BR",
                iso_3: "BRA",
                iso_num: 76
            },
            {
                id: 30,
                insee: 99225,
                name: "BRUNEI",
                iso_2: "BN",
                iso_3: "BRN",
                iso_num: 96
            },
            {
                id: 31,
                insee: 99111,
                name: "BULGARIE",
                iso_2: "BG",
                iso_3: "BGR",
                iso_num: 100
            },
            {
                id: 32,
                insee: 99331,
                name: "BURKINA FASO",
                iso_2: "BF",
                iso_3: "BFA",
                iso_num: 854
            },
            {
                id: 33,
                insee: 99321,
                name: "BURUNDI",
                iso_2: "BI",
                iso_3: "BDI",
                iso_num: 108
            },
            {
                id: 34,
                insee: 99234,
                name: "CAMBODGE",
                iso_2: "KH",
                iso_3: "KHM",
                iso_num: 116
            },
            {
                id: 35,
                insee: 99322,
                name: "CAMEROUN",
                iso_2: "CM",
                iso_3: "CMR",
                iso_num: 120
            },
            {
                id: 36,
                insee: 99401,
                name: "CANADA",
                iso_2: "CA",
                iso_3: "CAN",
                iso_num: 124
            },
            {
                id: 37,
                insee: 99396,
                name: "CAP-VERT",
                iso_2: "CV",
                iso_3: "CPV",
                iso_num: 132
            },
            {
                id: 38,
                insee: 99417,
                name: "CHILI",
                iso_2: "CL",
                iso_3: "CHL",
                iso_num: 152
            },
            {
                id: 39,
                insee: 99216,
                name: "CHINE",
                iso_2: "CN",
                iso_3: "CHN",
                iso_num: 156
            },
            {
                id: 40,
                insee: 99254,
                name: "CHYPRE",
                iso_2: "CY",
                iso_3: "CYP",
                iso_num: 196
            },
            {
                id: 41,
                insee: 99419,
                name: "COLOMBIE",
                iso_2: "CO",
                iso_3: "COL",
                iso_num: 170
            },
            {
                id: 42,
                insee: 99397,
                name: "COMORES",
                iso_2: "KM",
                iso_3: "COM",
                iso_num: 174
            },
            {
                id: 43,
                insee: 99324,
                name: "CONGO",
                iso_2: "CG",
                iso_3: "COG",
                iso_num: 178
            },
            {
                id: 44,
                insee: 99238,
                name: "COREE DU NORD",
                iso_2: "KP",
                iso_3: "PRK",
                iso_num: 408
            },
            {
                id: 45,
                insee: 99239,
                name: "COREE DU SUD",
                iso_2: "KR",
                iso_3: "KOR",
                iso_num: 410
            },
            {
                id: 46,
                insee: 99406,
                name: "COSTA RICA",
                iso_2: "CR",
                iso_3: "CRI",
                iso_num: 188
            },
            {
                id: 47,
                insee: 99326,
                name: 'COTE D"IVOIRE',
                iso_2: "CI",
                iso_3: "CIV",
                iso_num: 384
            },
            {
                id: 48,
                insee: 99119,
                name: "CROATIE",
                iso_2: "HR",
                iso_3: "HRV",
                iso_num: 191
            },
            {
                id: 49,
                insee: 99407,
                name: "CUBA",
                iso_2: "CU",
                iso_3: "CUB",
                iso_num: 192
            },
            {
                id: 50,
                insee: 99101,
                name: "DANEMARK",
                iso_2: "DK",
                iso_3: "DNK",
                iso_num: 208
            },
            {
                id: 51,
                insee: 99399,
                name: "DJIBOUTI",
                iso_2: "DJ",
                iso_3: "DJI",
                iso_num: 262
            },
            {
                id: 52,
                insee: 99301,
                name: "EGYPTE",
                iso_2: "EG",
                iso_3: "EGY",
                iso_num: 818
            },
            {
                id: 53,
                insee: 99247,
                name: "EMIRATS ARABES UNIS",
                iso_2: "AE",
                iso_3: "ARE",
                iso_num: 784
            },
            {
                id: 54,
                insee: 99420,
                name: "EQUATEUR",
                iso_2: "EC",
                iso_3: "ECU",
                iso_num: 218
            },
            {
                id: 55,
                insee: 99317,
                name: "ERYTHREE",
                iso_2: "ER",
                iso_3: "ERI",
                iso_num: 232
            },
            {
                id: 56,
                insee: 99134,
                name: "ESPAGNE",
                iso_2: "ES",
                iso_3: "ESP",
                iso_num: 724
            },
            {
                id: 57,
                insee: 99106,
                name: "ESTONIE",
                iso_2: "EE",
                iso_3: "EST",
                iso_num: 233
            },
            {
                id: 58,
                insee: 99404,
                name: "ETATS-UNIS",
                iso_2: "US",
                iso_3: "USA",
                iso_num: 840
            },
            {
                id: 59,
                insee: 99315,
                name: "ETHIOPIE",
                iso_2: "ET",
                iso_3: "ETH",
                iso_num: 231
            },
            {
                id: 60,
                insee: 99508,
                name: "FIDJI",
                iso_2: "FJ",
                iso_3: "FJI",
                iso_num: 242
            },
            {
                id: 61,
                insee: 99105,
                name: "FINLANDE",
                iso_2: "FI",
                iso_3: "FIN",
                iso_num: 246
            },
            {
                id: 0,
                insee: 99000,
                name: "FRANCE",
                iso_2: "FR",
                iso_3: "FRA",
                iso_num: 250
            },
            {
                id: 62,
                insee: 99328,
                name: "GABON",
                iso_2: "GA",
                iso_3: "GAB",
                iso_num: 266
            },
            {
                id: 63,
                insee: 99304,
                name: "GAMBIE",
                iso_2: "GM",
                iso_3: "GMB",
                iso_num: 270
            },
            {
                id: 64,
                insee: 99255,
                name: "GEORGIE",
                iso_2: "GE",
                iso_3: "GEO",
                iso_num: 268
            },
            {
                id: 65,
                insee: 99329,
                name: "GHANA",
                iso_2: "GH",
                iso_3: "GHA",
                iso_num: 288
            },
            {
                id: 66,
                insee: 99133,
                name: "GIBRALTAR",
                iso_2: "GI",
                iso_3: "GIB",
                iso_num: 292
            },
            {
                id: 67,
                insee: 99126,
                name: "GRECE",
                iso_2: "GR",
                iso_3: "GRC",
                iso_num: 300
            },
            {
                id: 68,
                insee: 99435,
                name: "GRENADE",
                iso_2: "GD",
                iso_3: "GRD",
                iso_num: 308
            },
            {
                id: 69,
                insee: 99430,
                name: "GROENLAND",
                iso_2: "GL",
                iso_3: "GRL",
                iso_num: 304
            },
            {
                id: 70,
                insee: 99505,
                name: "GUAM",
                iso_2: "GU",
                iso_3: "GUM",
                iso_num: 316
            },
            {
                id: 71,
                insee: 99409,
                name: "GUATEMALA",
                iso_2: "GT",
                iso_3: "GTM",
                iso_num: 320
            },
            {
                id: 72,
                insee: 99132,
                name: "GUERNESEY",
                iso_2: "GG",
                iso_3: "GGY",
                iso_num: 831
            },
            {
                id: 73,
                insee: 99330,
                name: "GUINEE",
                iso_2: "GN",
                iso_3: "GIN",
                iso_num: 324
            },
            {
                id: 74,
                insee: 99314,
                name: "GUINEE EQUATORIALE",
                iso_2: "GQ",
                iso_3: "GNQ",
                iso_num: 226
            },
            {
                id: 75,
                insee: 99392,
                name: "GUINEE-BISSAU",
                iso_2: "GW",
                iso_3: "GNB",
                iso_num: 624
            },
            {
                id: 76,
                insee: 99428,
                name: "GUYANA",
                iso_2: "GY",
                iso_3: "GUY",
                iso_num: 328
            },
            {
                id: 77,
                insee: 99410,
                name: "HAITI",
                iso_2: "HT",
                iso_3: "HTI",
                iso_num: 332
            },
            {
                id: 78,
                insee: 99411,
                name: "HONDURAS",
                iso_2: "HN",
                iso_3: "HND",
                iso_num: 340
            },
            {
                id: 79,
                insee: 99230,
                name: "HONG KONG",
                iso_2: "HK",
                iso_3: "HKG",
                iso_num: 344
            },
            {
                id: 80,
                insee: 99112,
                name: "HONGRIE",
                iso_2: "HU",
                iso_3: "HUN",
                iso_num: 348
            },
            {
                id: 81,
                insee: 99223,
                name: "INDE",
                iso_2: "IN",
                iso_3: "IND",
                iso_num: 356
            },
            {
                id: 82,
                insee: 99231,
                name: "INDONESIE",
                iso_2: "ID",
                iso_3: "IDN",
                iso_num: 360
            },
            {
                id: 83,
                insee: 99203,
                name: "IRAK",
                iso_2: "IQ",
                iso_3: "IRQ",
                iso_num: 368
            },
            {
                id: 84,
                insee: 99204,
                name: "IRAN",
                iso_2: "IR",
                iso_3: "IRN",
                iso_num: 364
            },
            {
                id: 85,
                insee: 99136,
                name: "IRLANDE",
                iso_2: "IE",
                iso_3: "IRL",
                iso_num: 372
            },
            {
                id: 86,
                insee: 99102,
                name: "ISLANDE",
                iso_2: "IS",
                iso_3: "ISL",
                iso_num: 352
            },
            {
                id: 87,
                insee: 99207,
                name: "ISRAEL",
                iso_2: "IL",
                iso_3: "ISR",
                iso_num: 376
            },
            {
                id: 88,
                insee: 99127,
                name: "ITALIE",
                iso_2: "IT",
                iso_3: "ITA",
                iso_num: 380
            },
            {
                id: 89,
                insee: 99426,
                name: "JAMAIQUE",
                iso_2: "JM",
                iso_3: "JAM",
                iso_num: 388
            },
            {
                id: 90,
                insee: 99217,
                name: "JAPON",
                iso_2: "JP",
                iso_3: "JPN",
                iso_num: 392
            },
            {
                id: 91,
                insee: 99132,
                name: "JERSEY",
                iso_2: "JE",
                iso_3: "JEY",
                iso_num: 832
            },
            {
                id: 92,
                insee: 99222,
                name: "JORDANIE",
                iso_2: "JO",
                iso_3: "JOR",
                iso_num: 400
            },
            {
                id: 93,
                insee: 99256,
                name: "KAZAKHSTAN",
                iso_2: "KZ",
                iso_3: "KAZ",
                iso_num: 398
            },
            {
                id: 94,
                insee: 99332,
                name: "KENYA",
                iso_2: "KE",
                iso_3: "KEN",
                iso_num: 404
            },
            {
                id: 95,
                insee: 99257,
                name: "KIRGHIZISTAN",
                iso_2: "KG",
                iso_3: "KGZ",
                iso_num: 417
            },
            {
                id: 96,
                insee: 99513,
                name: "KIRIBATI",
                iso_2: "KI",
                iso_3: "KIR",
                iso_num: 296
            },
            {
                id: 97,
                insee: 99157,
                name: "KOSOVO",
                iso_2: "XK",
                iso_3: "RKS",
                iso_num: 153
            },
            {
                id: 98,
                insee: 99240,
                name: "KOWEIT",
                iso_2: "KW",
                iso_3: "KWT",
                iso_num: 414
            },
            {
                id: 99,
                insee: 99241,
                name: "LAOS",
                iso_2: "LA",
                iso_3: "LAO",
                iso_num: 418
            },
            {
                id: 100,
                insee: 99348,
                name: "LESOTHO",
                iso_2: "LS",
                iso_3: "LSO",
                iso_num: 426
            },
            {
                id: 101,
                insee: 99107,
                name: "LETTONIE",
                iso_2: "LV",
                iso_3: "LVA",
                iso_num: 428
            },
            {
                id: 102,
                insee: 99205,
                name: "LIBAN",
                iso_2: "LB",
                iso_3: "LBN",
                iso_num: 422
            },
            {
                id: 103,
                insee: 99302,
                name: "LIBERIA",
                iso_2: "LR",
                iso_3: "LBR",
                iso_num: 430
            },
            {
                id: 104,
                insee: 99316,
                name: "LIBYE",
                iso_2: "LY",
                iso_3: "LBY",
                iso_num: 434
            },
            {
                id: 105,
                insee: 99113,
                name: "LIECHTENSTEIN",
                iso_2: "LI",
                iso_3: "LIE",
                iso_num: 438
            },
            {
                id: 106,
                insee: 99108,
                name: "LITUANIE",
                iso_2: "LT",
                iso_3: "LTU",
                iso_num: 440
            },
            {
                id: 107,
                insee: 99137,
                name: "LUXEMBOURG",
                iso_2: "LU",
                iso_3: "LUX",
                iso_num: 442
            },
            {
                id: 108,
                insee: 99232,
                name: "MACAO",
                iso_2: "MO",
                iso_3: "MAC",
                iso_num: 446
            },
            {
                id: 109,
                insee: 99156,
                name: "MACEDOINE",
                iso_2: "MK",
                iso_3: "MKD",
                iso_num: 807
            },
            {
                id: 110,
                insee: 99333,
                name: "MADAGASCAR",
                iso_2: "MG",
                iso_3: "MDG",
                iso_num: 450
            },
            {
                id: 111,
                insee: 99227,
                name: "MALAISIE",
                iso_2: "MY",
                iso_3: "MYS",
                iso_num: 458
            },
            {
                id: 112,
                insee: 99334,
                name: "MALAWI",
                iso_2: "MW",
                iso_3: "MWI",
                iso_num: 454
            },
            {
                id: 113,
                insee: 99229,
                name: "MALDIVES",
                iso_2: "MV",
                iso_3: "MDV",
                iso_num: 462
            },
            {
                id: 114,
                insee: 99335,
                name: "MALI",
                iso_2: "ML",
                iso_3: "MLI",
                iso_num: 466
            },
            {
                id: 115,
                insee: 99144,
                name: "MALTE",
                iso_2: "MT",
                iso_3: "MLT",
                iso_num: 470
            },
            {
                id: 116,
                insee: 99350,
                name: "MAROC",
                iso_2: "MA",
                iso_3: "MAR",
                iso_num: 504
            },
            {
                id: 117,
                insee: 99390,
                name: "MAURICE",
                iso_2: "MU",
                iso_3: "MUS",
                iso_num: 480
            },
            {
                id: 118,
                insee: 99336,
                name: "MAURITANIE",
                iso_2: "MR",
                iso_3: "MRT",
                iso_num: 478
            },
            {
                id: 119,
                insee: 99405,
                name: "MEXIQUE",
                iso_2: "MX",
                iso_3: "MEX",
                iso_num: 484
            },
            {
                id: 120,
                insee: 99516,
                name: "MICRONESIE",
                iso_2: "FM",
                iso_3: "FSM",
                iso_num: 583
            },
            {
                id: 121,
                insee: 99151,
                name: "MOLDAVIE",
                iso_2: "MD",
                iso_3: "MDA",
                iso_num: 498
            },
            {
                id: 122,
                insee: 99138,
                name: "MONACO",
                iso_2: "MC",
                iso_3: "MCO",
                iso_num: 492
            },
            {
                id: 123,
                insee: 99242,
                name: "MONGOLIE",
                iso_2: "MN",
                iso_3: "MNG",
                iso_num: 496
            },
            {
                id: 124,
                insee: 99120,
                name: "MONTENEGRO",
                iso_2: "ME",
                iso_3: "MNE",
                iso_num: 499
            },
            {
                id: 125,
                insee: 99425,
                name: "MONTSERRAT",
                iso_2: "MS",
                iso_3: "MSR",
                iso_num: 500
            },
            {
                id: 126,
                insee: 99393,
                name: "MOZAMBIQUE",
                iso_2: "MZ",
                iso_3: "MOZ",
                iso_num: 508
            },
            {
                id: 127,
                insee: 99224,
                name: "MYANMAR",
                iso_2: "MM",
                iso_3: "MMR",
                iso_num: 104
            },
            {
                id: 128,
                insee: 99311,
                name: "NAMIBIE",
                iso_2: "NA",
                iso_3: "NAM",
                iso_num: 516
            },
            {
                id: 129,
                insee: 99507,
                name: "NAURU",
                iso_2: "NR",
                iso_3: "NRU",
                iso_num: 520
            },
            {
                id: 130,
                insee: 99215,
                name: "NEPAL",
                iso_2: "NP",
                iso_3: "NPL",
                iso_num: 524
            },
            {
                id: 131,
                insee: 99412,
                name: "NICARAGUA",
                iso_2: "NI",
                iso_3: "NIC",
                iso_num: 558
            },
            {
                id: 132,
                insee: 99337,
                name: "NIGER",
                iso_2: "NE",
                iso_3: "NER",
                iso_num: 562
            },
            {
                id: 133,
                insee: 99338,
                name: "NIGERIA",
                iso_2: "NG",
                iso_3: "NGA",
                iso_num: 566
            },
            {
                id: 134,
                insee: 99103,
                name: "NORVEGE",
                iso_2: "NO",
                iso_3: "NOR",
                iso_num: 578
            },
            {
                id: 135,
                insee: 99502,
                name: "NOUVELLE-ZELANDE",
                iso_2: "NZ",
                iso_3: "NZL",
                iso_num: 554
            },
            {
                id: 136,
                insee: 99250,
                name: "OMAN",
                iso_2: "OM",
                iso_3: "OMN",
                iso_num: 512
            },
            {
                id: 137,
                insee: 99339,
                name: "OUGANDA",
                iso_2: "UG",
                iso_3: "UGA",
                iso_num: 800
            },
            {
                id: 138,
                insee: 99258,
                name: "OUZBEKISTAN",
                iso_2: "UZ",
                iso_3: "UZB",
                iso_num: 860
            },
            {
                id: 139,
                insee: 99213,
                name: "PAKISTAN",
                iso_2: "PK",
                iso_3: "PAK",
                iso_num: 586
            },
            {
                id: 140,
                insee: 99261,
                name: "PALESTINE",
                iso_2: "PS",
                iso_3: "PSE",
                iso_num: 275
            },
            {
                id: 141,
                insee: 99413,
                name: "PANAMA",
                iso_2: "PA",
                iso_3: "PAN",
                iso_num: 591
            },
            {
                id: 142,
                insee: 99510,
                name: "PAPOUASIE-NOUVELLE-GUINEE",
                iso_2: "PG",
                iso_3: "PNG",
                iso_num: 598
            },
            {
                id: 143,
                insee: 99421,
                name: "PARAGUAY",
                iso_2: "PY",
                iso_3: "PRY",
                iso_num: 600
            },
            {
                id: 144,
                insee: 99135,
                name: "PAYS-BAS",
                iso_2: "NL",
                iso_3: "NLD",
                iso_num: 528
            },
            {
                id: 145,
                insee: 99422,
                name: "PEROU",
                iso_2: "PE",
                iso_3: "PER",
                iso_num: 604
            },
            {
                id: 146,
                insee: 99220,
                name: "PHILIPPINES",
                iso_2: "PH",
                iso_3: "PHL",
                iso_num: 608
            },
            {
                id: 147,
                insee: 99122,
                name: "POLOGNE",
                iso_2: "PL",
                iso_3: "POL",
                iso_num: 616
            },
            {
                id: 148,
                insee: 99139,
                name: "PORTUGAL",
                iso_2: "PT",
                iso_3: "PRT",
                iso_num: 620
            },
            {
                id: 149,
                insee: 99432,
                name: "PUERTO RICO",
                iso_2: "PR",
                iso_3: "PRI",
                iso_num: 630
            },
            {
                id: 150,
                insee: 99248,
                name: "QATAR",
                iso_2: "QA",
                iso_3: "QAT",
                iso_num: 634
            },
            {
                id: 151,
                insee: 99323,
                name: "REPUBLIQUE CENTRAFRICAINE",
                iso_2: "CF",
                iso_3: "CAF",
                iso_num: 140
            },
            {
                id: 152,
                insee: 99312,
                name: "REPUBLIQUE DEMOCRATIQUE DU CONGO",
                iso_2: "CD",
                iso_3: "COD",
                iso_num: 180
            },
            {
                id: 153,
                insee: 99408,
                name: "REPUBLIQUE DOMINICAINE",
                iso_2: "DO",
                iso_3: "DOM",
                iso_num: 214
            },
            {
                id: 154,
                insee: 99116,
                name: "REPUBLIQUE TCHEQUE",
                iso_2: "CZ",
                iso_3: "CZE",
                iso_num: 203
            },
            {
                id: 155,
                insee: 99114,
                name: "ROUMANIE",
                iso_2: "RO",
                iso_3: "ROU",
                iso_num: 642
            },
            {
                id: 156,
                insee: 99132,
                name: "ROYAUME-UNI",
                iso_2: "GB",
                iso_3: "GBR",
                iso_num: 826
            },
            {
                id: 157,
                insee: 99123,
                name: "RUSSIE",
                iso_2: "RU",
                iso_3: "RUS",
                iso_num: 643
            },
            {
                id: 158,
                insee: 99340,
                name: "RWANDA",
                iso_2: "RW",
                iso_3: "RWA",
                iso_num: 646
            },
            {
                id: 159,
                insee: 99389,
                name: "SAHARA OCCIDENTAL",
                iso_2: "EH",
                iso_3: "ESH",
                iso_num: 732
            },
            {
                id: 160,
                insee: 99439,
                name: "SAINTE-LUCIE",
                iso_2: "LC",
                iso_3: "LCA",
                iso_num: 662
            },
            {
                id: 161,
                insee: 99445,
                name: "SAINT-MARTIN (PARTIE NEERLANDAISE)",
                iso_2: "SX",
                iso_3: "SXM",
                iso_num: 534
            },
            {
                id: 162,
                insee: 99440,
                name: "SAINT-VINCENT-ET-LES GRENADINES",
                iso_2: "VC",
                iso_3: "VCT",
                iso_num: 670
            },
            {
                id: 163,
                insee: 99414,
                name: "SALVADOR",
                iso_2: "SV",
                iso_3: "SLV",
                iso_num: 222
            },
            {
                id: 164,
                insee: 99394,
                name: "SAO TOME-ET-PRINCIPE",
                iso_2: "ST",
                iso_3: "STP",
                iso_num: 678
            },
            {
                id: 165,
                insee: 99341,
                name: "SENEGAL",
                iso_2: "SN",
                iso_3: "SEN",
                iso_num: 686
            },
            {
                id: 166,
                insee: 99121,
                name: "SERBIE",
                iso_2: "RS",
                iso_3: "SRB",
                iso_num: 688
            },
            {
                id: 167,
                insee: 99398,
                name: "SEYCHELLES",
                iso_2: "SC",
                iso_3: "SYC",
                iso_num: 690
            },
            {
                id: 168,
                insee: 99342,
                name: "SIERRA LEONE",
                iso_2: "SL",
                iso_3: "SLE",
                iso_num: 694
            },
            {
                id: 169,
                insee: 99226,
                name: "SINGAPOUR",
                iso_2: "SG",
                iso_3: "SGP",
                iso_num: 702
            },
            {
                id: 170,
                insee: 99117,
                name: "SLOVAQUIE",
                iso_2: "SK",
                iso_3: "SVK",
                iso_num: 703
            },
            {
                id: 171,
                insee: 99145,
                name: "SLOVENIE",
                iso_2: "SI",
                iso_3: "SVN",
                iso_num: 705
            },
            {
                id: 172,
                insee: 99318,
                name: "SOMALIE",
                iso_2: "SO",
                iso_3: "SOM",
                iso_num: 706
            },
            {
                id: 173,
                insee: 99343,
                name: "SOUDAN",
                iso_2: "SD",
                iso_3: "SDN",
                iso_num: 736
            },
            {
                id: 174,
                insee: 99349,
                name: "SOUDAN DU SUD",
                iso_2: "SS",
                iso_3: "SSD",
                iso_num: 728
            },
            {
                id: 175,
                insee: 99235,
                name: "SRI LANKA",
                iso_2: "LK",
                iso_3: "LKA",
                iso_num: 144
            },
            {
                id: 176,
                insee: 99104,
                name: "SUEDE",
                iso_2: "SE",
                iso_3: "SWE",
                iso_num: 752
            },
            {
                id: 177,
                insee: 99140,
                name: "SUISSE",
                iso_2: "CH",
                iso_3: "CHE",
                iso_num: 756
            },
            {
                id: 178,
                insee: 99437,
                name: "SURINAME",
                iso_2: "SR",
                iso_3: "SUR",
                iso_num: 740
            },
            {
                id: 179,
                insee: 99391,
                name: "SWAZILAND",
                iso_2: "SZ",
                iso_3: "SWZ",
                iso_num: 748
            },
            {
                id: 180,
                insee: 99206,
                name: "SYRIE",
                iso_2: "SY",
                iso_3: "SYR",
                iso_num: 760
            },
            {
                id: 181,
                insee: 99259,
                name: "TADJIKISTAN",
                iso_2: "TJ",
                iso_3: "TJK",
                iso_num: 762
            },
            {
                id: 182,
                insee: 99236,
                name: "TAIWAN",
                iso_2: "TW",
                iso_3: "TWN",
                iso_num: 158
            },
            {
                id: 183,
                insee: 99309,
                name: "TANZANIE",
                iso_2: "TZ",
                iso_3: "TZA",
                iso_num: 834
            },
            {
                id: 184,
                insee: 99344,
                name: "TCHAD",
                iso_2: "TD",
                iso_3: "TCD",
                iso_num: 148
            },
            {
                id: 185,
                insee: 99219,
                name: "THAILANDE",
                iso_2: "TH",
                iso_3: "THA",
                iso_num: 764
            },
            {
                id: 186,
                insee: 99262,
                name: "TIMOR ORIENTAL",
                iso_2: "TL",
                iso_3: "TLS",
                iso_num: 626
            },
            {
                id: 187,
                insee: 99345,
                name: "TOGO",
                iso_2: "TG",
                iso_3: "TGO",
                iso_num: 768
            },
            {
                id: 188,
                insee: 99509,
                name: "TONGA",
                iso_2: "TO",
                iso_3: "TON",
                iso_num: 776
            },
            {
                id: 189,
                insee: 99433,
                name: "TRINITE-ET-TOBAGO",
                iso_2: "TT",
                iso_3: "TTO",
                iso_num: 780
            },
            {
                id: 190,
                insee: 99351,
                name: "TUNISIE",
                iso_2: "TN",
                iso_3: "TUN",
                iso_num: 788
            },
            {
                id: 191,
                insee: 99260,
                name: "TURKMENISTAN",
                iso_2: "TM",
                iso_3: "TKM",
                iso_num: 795
            },
            {
                id: 192,
                insee: 99208,
                name: "TURQUIE",
                iso_2: "TR",
                iso_3: "TUR",
                iso_num: 792
            },
            {
                id: 193,
                insee: 99511,
                name: "TUVALU",
                iso_2: "TV",
                iso_3: "TUV",
                iso_num: 798
            },
            {
                id: 194,
                insee: 99155,
                name: "UKRAINE",
                iso_2: "UA",
                iso_3: "UKR",
                iso_num: 804
            },
            {
                id: 195,
                insee: 99423,
                name: "URUGUAY",
                iso_2: "UY",
                iso_3: "URY",
                iso_num: 858
            },
            {
                id: 196,
                insee: 99514,
                name: "VANUATU",
                iso_2: "VU",
                iso_3: "VUT",
                iso_num: 548
            },
            {
                id: 197,
                insee: 99424,
                name: "VENEZUELA",
                iso_2: "VE",
                iso_3: "VEN",
                iso_num: 862
            },
            {
                id: 198,
                insee: 99243,
                name: "VIETNAM",
                iso_2: "VN",
                iso_3: "VNM",
                iso_num: 704
            },
            {
                id: 199,
                insee: 99251,
                name: "YEMEN",
                iso_2: "YE",
                iso_3: "YEM",
                iso_num: 887
            },
            {
                id: 200,
                insee: 99346,
                name: "ZAMBIE",
                iso_2: "ZM",
                iso_3: "ZMB",
                iso_num: 894
            },
            {
                id: 201,
                insee: 99310,
                name: "ZIMBABWE",
                iso_2: "ZW",
                iso_3: "ZWE",
                iso_num: 716
            }
        ];

        return {countries};
    }
}

import {AgmCoreModule} from "@agm/core";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {
    CommonModule,
    LocationStrategy,
    PathLocationStrategy
} from "@angular/common";
import {BrowserModule, Title} from "@angular/platform-browser";
import {environment} from "../environments/environment";

import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {NgModule, ErrorHandler, LOCALE_ID} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {Angulartics2Module} from "angulartics2";
import {ClipboardModule} from "ngx-clipboard";
import {FullComponent} from "./layouts/full/full.component";
import {BlankComponent} from "./layouts/blank/blank.component";
import {NotFoundComponent} from "./shared/404/404.component";
import {NavigationComponent} from "./shared/admin-navbar/admin-navbar.component";
import {ErrorComponent} from "./shared/error/error.component";
import {SharedModule} from "./shared/shared.module";
import {BreadcrumbComponent} from "./shared/breadcrumb/breadcrumb.component";
import {NgbModule, NgbProgressbarConfig} from "@ng-bootstrap/ng-bootstrap";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {PERFECT_SCROLLBAR_CONFIG} from "ngx-perfect-scrollbar";
import {PerfectScrollbarConfigInterface} from "ngx-perfect-scrollbar";
import {SpinnerComponent} from "./shared/spinner.component";
import {Approutes} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {PartnerOffersComponent} from "./partner-offers/partner-offers.component";
import {SponsorshipComponent} from "./sponsorship/sponsorship.component";
import {MedicationReblozylComponent} from "./medication-reblozyl/medication-reblozyl.component";
import {UploadAvatarModule} from "./upload-avatar/upload-avatar.module";
import {SidebarModule} from "./shared/sidebar/sidebar.module";
import {authFooterModule} from "./shared/auth-footer/auth-footer.module";
import {registerLocaleData} from "@angular/common";
import {MaintenanceComponent} from "./maintenance/maintenance.component";
import localeFr from "@angular/common/locales/fr";
// the second parameter 'fr-FR' is optional
registerLocaleData(localeFr, "fr-FR");

import {
    AuthenticationService,
    ApiService,
    AlertService,
    StepGuard,
    AdminGuard,
    TeamService,
    AssignationService,
    AccountService,
    PatientService,
    AirbrakeErrorHandler,
    ProResolverService
} from "./providers";
import {HttpConfigInterceptor} from "./providers/httpConfig.interceptor";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true
};

@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent,
        FullComponent,
        BlankComponent,
        NavigationComponent,
        BreadcrumbComponent,
        NotFoundComponent,
        ErrorComponent,
        PartnerOffersComponent,
        SponsorshipComponent,
        MedicationReblozylComponent,
        MaintenanceComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        UploadAvatarModule,
        NgbModule,
        RouterModule.forRoot(Approutes, {useHash: true}),
        Angulartics2Module.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: environment.google_api_key,
            libraries: ["places"],
            region: "FR",
            language: "fr-FR"
        }),
        PerfectScrollbarModule,
        SharedModule,
        ClipboardModule,
        SidebarModule,
        authFooterModule
    ],
    providers: [
        Title,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        {
            provide: ErrorHandler,
            useClass: AirbrakeErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: "fr-FR"
        },
        AuthenticationService,
        ApiService,
        AlertService,
        StepGuard,
        AdminGuard,
        TeamService,
        AssignationService,
        AccountService,
        PatientService,
        NgbProgressbarConfig,
        ProResolverService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

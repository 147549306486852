import {Component, Input, OnInit, Output, EventEmitter} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {Router, NavigationEnd, ActivatedRoute} from "@angular/router";
import "rxjs/add/operator/filter";
import "rxjs/add/operator/map";
import "rxjs/add/operator/mergeMap";

@Component({
    selector: "breadcrumb",
    templateUrl: "./breadcrumb.component.html",
    styleUrls: ["./breadcrumb.component.scss"]
})
export class BreadcrumbComponent implements OnInit {
    @Output() hiddenElement: EventEmitter<object> = new EventEmitter();
    @Input() layout;
    pageInfo;
    invoiceInfoCheck;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title
    ) {
        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .map(() => this.activatedRoute)
            .map(route => {
                while (route.firstChild) route = route.firstChild;
                return route;
            })
            .filter(route => route.outlet === "primary")
            .mergeMap(route => route.data)
            .subscribe(event => {
                this.titleService.setTitle(event["title"]);
                this.pageInfo = event;
                const urlsHiddenFooter = [
                    "facture",
                    "elements-facturation",
                    "informations-facturation",
                    "informations-facturation",
                    "signature"
                ];
                const regex = /(\?|\&)([^=]+)\=([^&]+)/i;
                const url = this.router.url.split("/").pop().replace(regex, "");
                this.invoiceInfoCheck = urlsHiddenFooter.includes(url);
                const isMobileScreen = window.innerWidth < 768 ? true : false;
                const deletePaddingContainerFluid =
                    isMobileScreen && url === "signature";
                this.hiddenElement.emit({
                    hiddenInformationFooter: this.invoiceInfoCheck,
                    deletePaddingContainerFluid
                });
            });
    }

    ngOnInit() {}
}

import {Component, Input, OnInit} from "@angular/core";

declare const $: any;

@Component({
    selector: "maintenance",
    templateUrl: "./maintenance.component.html",
    styleUrls: ["./maintenance.component.scss"]
})
export class MaintenanceComponent implements OnInit {
    constructor() {}

    ngOnInit() {
        $("#main-wrapper").addClass("mini-sidebar");
        $("body").trigger("resize");
    }
}

import {Pipe, PipeTransform} from "@angular/core";
import * as moment from "moment";

@Pipe({
    name: "momentDate"
})
export class MomentDatePipe implements PipeTransform {
    transform(date: any, args?: any): any {
        moment.locale("FR");

        if (!date) {
            return "-";
        }

        date = moment(date);
        if (!date.isValid()) {
            return "-";
        }

        if (args === "full") {
            return date.format("dddd DD MMMM YYYY à HH[h]mm");
        }

        return "NotFormat";
    }
}

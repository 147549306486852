import {RouteInfo} from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
    {
        path: "/dashboard",
        href: false,
        link: "",
        title: "Accueil",
        icon: "mdi mdi-home",
        class: "",
        label: "",
        labelClass: "",
        modal: false,
        extralink: false,
        submenu: [],
        hideToLightPros: false
    },
    {
        path: "",
        href: true,
        link: "",
        title: "Missions libheros",
        icon: "mdi mdi-briefcase variant",
        class: "has-arrow",
        label: "",
        labelClass: "",
        modal: false,
        extralink: false,
        hideToLightPros: true,
        submenu: [
            {
                path: "/missions",
                href: false,
                link: "",
                title: "Missions",
                icon: "mdi mdi-briefcase",
                class: "",
                label: "",
                labelClass: "",
                modal: false,
                extralink: false,
                submenu: [],
                hideToLightPros: false
            },
            {
                path: "/missions/facture",
                href: false,
                link: "",
                title: "Facturations des missions",
                icon: "mdi mdi-cash",
                class: "",
                label: "",
                labelClass: "",
                modal: false,
                extralink: false,
                submenu: [],
                hideToLightPros: false
            }
        ]
    },
    {
        path: "/rendez-vous",
        href: false,
        link: "",
        title: "Demandes de soin",
        icon: "mdi mdi-calendar",
        class: "",
        label: "",
        labelClass: "",
        modal: false,
        extralink: false,
        submenu: [],
        hideToLightPros: true
    },
    // {
    //   path: '/flow-cabinet',
    //   href: false,
    //   link: '',
    //   title: 'Dépistage COVID en cabinet',
    //   icon: 'mdi mdi-calendar',
    //   class: '',
    //   label: '',
    //   labelClass: '',
    //   modal: false,
    //   extralink: false,
    //   submenu: [],
    //   hideToLightPros: true
    // },
    {
        path: "",
        href: false,
        link: "",
        title: "Cabinet",
        icon: "mdi mdi-home-modern",
        class: "has-arrow",
        label: "",
        labelClass: "",
        modal: false,
        extralink: false,
        hideToLightPros: true,
        submenu: [
            {
                path: "/settings/office",
                href: false,
                link: "",
                title: "Infos vues par les patients",
                icon: "mdi mdi-information-variant",
                class: "",
                label: "",
                labelClass: "",
                modal: false,
                extralink: false,
                submenu: [],
                hideToLightPros: true
            },
            {
                path: "/settings/cares",
                href: false,
                link: "",
                title: "Soins acceptés",
                icon: "mdi mdi-hospital",
                class: "",
                label: "",
                labelClass: "",
                modal: false,
                extralink: false,
                submenu: [],
                hideToLightPros: true
            },
            {
                path: "/settings/tour",
                href: false,
                link: "",
                title: "Zone de tournée",
                icon: "mdi mdi-map-marker-radius",
                class: "",
                label: "",
                labelClass: "",
                modal: false,
                extralink: false,
                submenu: [],
                hideToLightPros: true
            },
            {
                path: "/settings/collegues",
                href: false,
                link: "",
                title: "Collègues",
                icon: "mdi mdi-account-multiple",
                class: "",
                label: "",
                labelClass: "",
                modal: false,
                extralink: false,
                submenu: [],
                hideToLightPros: true
            },
            {
                path: "/settings/frames",
                href: false,
                link: "",
                title: "Horaires de travail",
                icon: "mdi mdi-timetable",
                class: "",
                label: "",
                labelClass: "",
                modal: false,
                extralink: false,
                submenu: [],
                hideToLightPros: true
            },
            {
                path: "/settings/specialties",
                href: false,
                link: "",
                title: "Prises en charge",
                icon: "mdi mdi-wheelchair-accessibility",
                class: "",
                label: "",
                labelClass: "",
                modal: false,
                extralink: false,
                submenu: [],
                hideToLightPros: true
            }
        ]
    },
    {
        path: "/profile/personal",
        href: false,
        link: "",
        title: "Mon compte",
        icon: "mdi mdi-account",
        class: "",
        label: "",
        labelClass: "",
        modal: false,
        extralink: false,
        submenu: [],
        hideToLightPros: false
    },
    {
        path: "",
        href: false,
        link: "",
        title: "Charte",
        icon: "mdi mdi-pencil",
        class: "charter-item",
        label: "",
        labelClass: "",
        modal: true,
        extralink: false,
        submenu: [],
        hideToLightPros: true
    },
    {
        path: "",
        href: true,
        link: "",
        title: "Parcours de soins et traitements",
        icon: "mdi mdi-hospital-building",
        class: "has-arrow",
        label: "",
        labelClass: "",
        modal: false,
        extralink: false,
        hideToLightPros: true,
        submenu: [
            {
                path: "",
                href: false,
                link: "",
                title: "Pathologies",
                icon: "pathology",
                class: "has-arrow",
                label: "",
                labelClass: "",
                modal: false,
                extralink: false,
                hideToLightPros: true,
                submenu: [
                    {
                        path: "",
                        href: true,
                        link: "https://libheros.fr/pros/projet-dermatite-atopique",
                        title: "Dermatite atopique",
                        icon: "",
                        class: "",
                        label: "",
                        labelClass: "",
                        modal: false,
                        extralink: true,
                        submenu: [],
                        hideToLightPros: true
                    },
                    {
                        path: "",
                        href: true,
                        link: "https://libheros.fr/parcours/asthme/infirmiers",
                        title: "Asthme sévère",
                        icon: "",
                        class: "",
                        label: "",
                        labelClass: "",
                        modal: false,
                        extralink: true,
                        submenu: [],
                        hideToLightPros: true
                    },
                    {
                        path: "",
                        href: true,
                        link: "https://libheros.fr/parcours/sclerose-en-plaques/infirmiers",
                        title: "Sclérose en plaques",
                        icon: "",
                        class: "",
                        label: "",
                        labelClass: "",
                        modal: false,
                        extralink: true,
                        submenu: [],
                        hideToLightPros: true
                    }
                ]
            },
            {
                path: "",
                href: false,
                link: "",
                title: "Médicaments",
                icon: "pills",
                class: "has-arrow",
                label: "",
                labelClass: "",
                modal: false,
                extralink: false,
                hideToLightPros: true,
                submenu: [
                    {
                        id: "reblozyl",
                        path: "/reblozyl",
                        href: true,
                        link: "",
                        title: "REBLOZYL® \n(luspatercept)",
                        icon: "",
                        class: "",
                        label: "",
                        labelClass: "",
                        modal: false,
                        extralink: false,
                        submenu: [],
                        hideToLightPros: true
                    }
                ]
            }
        ]
    },
    {
        path: "/offres-partenaires",
        href: false,
        link: "",
        title: "Offres partenaires",
        icon: "mdi mdi-gift",
        class: "",
        label: "",
        labelClass: "",
        modal: false,
        extralink: false,
        submenu: [],
        hideToLightPros: true
    },
    {
        path: "/sponsorship",
        href: false,
        link: "",
        title: "Parrainage",
        icon: "mdi mdi-account-multiple-plus",
        class: "",
        label: "",
        labelClass: "",
        modal: false,
        extralink: false,
        submenu: [],
        hideToLightPros: true
    },
    {
        path: "/alerts",
        href: false,
        link: "",
        title: "Alerts",
        icon: "mdi mdi-bell",
        class: "",
        label: "",
        labelClass: "",
        modal: false,
        extralink: false,
        submenu: [],
        hideToLightPros: true
    }
];

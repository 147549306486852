import {Component} from "@angular/core";
import {AuthenticationService} from "../../providers/index";
import {environment} from "../../../environments/environment";

declare var $: any;

@Component({
    selector: "authfooter-cmp",
    templateUrl: "./auth-footer.component.html",
    styleUrls: ["./auth-footer.component.scss"]
})
export class AuthFooter {
    public version = environment.version;

    constructor(private authenticationService: AuthenticationService) {}
}

import {Injectable} from "@angular/core";

import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ApiService {
    constructor(private http: HttpClient) {}

    static updateUrl(req: string) {
        //@ts-ignore
        return environment.origin + req;
    }

    get(url: string) {
        url = ApiService.updateUrl(url);
        return this.http.get(url, {});
    }

    post(url: string, body: any) {
        url = ApiService.updateUrl(url);
        return this.http.post(url, body);
    }

    put(url: string, body: any) {
        url = ApiService.updateUrl(url);
        return this.http.put(url, body);
    }

    delete(url: string) {
        url = ApiService.updateUrl(url);
        return this.http.delete(url);
    }
}

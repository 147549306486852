import {Component} from "@angular/core";
import {TeamService} from "./providers";
import {environment} from "../environments/environment";

let OneSignal;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"]
})
export class AppComponent {
    oneSignalId: any;

    constructor(private teamService: TeamService) {
        if (environment.production) {
            this.init();
        }
    }

    title = "app";

    public init() {
        this.addScript("https://cdn.onesignal.com/sdks/OneSignalSDK.js", () => {
            this.initOneSignal();
        });
    }

    addScript(fileSrc, callback) {
        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.onload = callback;
        script.src = fileSrc;
        head.appendChild(script);
    }

    initOneSignal() {
        OneSignal = window["OneSignal"] || [];
        OneSignal.push(() => {
            OneSignal.init({
                appId: "e74baa43-4201-4d50-8d96-d2c57bea2d56",
                autoRegister: false,
                allowLocalhostAsSecureOrigin: true,
                notifyButton: {
                    enable: false
                }
            });

            OneSignal.isPushNotificationsEnabled(
                isEnabled => {
                    if (!isEnabled) {
                        this.getUserIDAndUpdatelocalStorage();
                        if (localStorage.getItem("accessToken")) {
                            this.updateServerUserOneSignalId(this.oneSignalId);
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            );

            OneSignal.on("subscriptionChange", isSubscribed => {
                if (localStorage.getItem("accessToken")) {
                    if (!isSubscribed) {
                        const oneSignalId =
                            "nnnnnnn-nnnn-nnnn-nnnn-nnnnnnnnnnn";
                        this.updateServerUserOneSignalId(oneSignalId);
                    } else {
                        OneSignal.getUserId().then(userId => {
                            this.updateServerUserOneSignalId(userId);
                        });
                    }
                } else {
                    this.getUserIDAndUpdatelocalStorage();
                }
            });
        });
    }

    getUserIDAndUpdatelocalStorage() {
        OneSignal.getUserId().then(userId => {
            this.oneSignalId = userId;
            localStorage.setItem("oneSignalId", this.oneSignalId);
        });
    }

    updateServerUserOneSignalId(oneSignalId) {
        this.teamService.sendoneSignalId(oneSignalId).subscribe();
    }
}

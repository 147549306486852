import {Injectable, ErrorHandler, Injector} from "@angular/core";
import {Router} from "@angular/router";

@Injectable()
export class AirbrakeErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {}

    handleError(error: any) {
        console.log("error", error);
        //return this.router.navigateByUrl('/error');
    }

    get router(): Router {
        return this.injector.get(Router);
    }
}

import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";

import {PerfectScrollbarConfigInterface} from "ngx-perfect-scrollbar";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: "full-layout",
    templateUrl: "./full.component.html",
    styleUrls: ["./full.component.scss"]
})
export class FullComponent implements OnInit {
    showMinisidebar: boolean;
    showDarktheme = false;
    public innerWidth: number;
    hiddenInformationFooter: any;
    navBarCollapseClicked: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    deletePaddingContainerFluid: false;

    public config: PerfectScrollbarConfigInterface = {};

    constructor(public router: Router) {}

    ngOnInit() {
        this.innerWidth = window.innerWidth;
        this.showMinisidebar = this.innerWidth <= 768;
    }

    receiveChildData(data) {
        this.hiddenInformationFooter = data.hiddenInformationFooter;
        this.deletePaddingContainerFluid = data.deletePaddingContainerFluid;
    }

    updateNavbarCollapse(showSideBar: boolean) {
        this.showMinisidebar = showSideBar;
        this.navBarCollapseClicked.next(showSideBar);
    }

    openSidebar(openSideBar: boolean) {
        this.showMinisidebar = openSideBar;
        this.navBarCollapseClicked.next(openSideBar);
    }
}

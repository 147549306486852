import {
    Component,
    OnInit,
    NgZone,
    ViewChild,
    Input,
    Output,
    EventEmitter
} from "@angular/core";
import {Observable} from "rxjs";
import {
    AlertService,
    AuthenticationService,
    TeamService
} from "../../providers";
import * as charterService from "../../providers/charter.service";
import {ROUTES} from "./menu-items";
import {NgbModal, ModalDismissReasons} from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import {Router} from "@angular/router";
import {User} from "../../_models";

declare var $: any;

@Component({
    selector: "sidebar-cmp",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
    showMenu: string = "";
    modalReference: any;
    closeResult: any;
    user;
    avatarUrl;
    clock_tick = Date.now();
    showSubMenu: string = "";
    charterHTML: string = "";
    configStep: string = "";
    public innerWidth: any;

    public sidebarnavItems: any[];
    @ViewChild("avatar", {static: true}) public avatarModal;
    @ViewChild("charter", {static: true}) public charterModal;

    @Input()
    navbarCollapseClicked: Observable<boolean>;

    @Output()
    openSideBar = new EventEmitter<boolean>();

    constructor(
        private alertService: AlertService,
        private ngZone: NgZone,
        private teamService: TeamService,
        private router: Router,
        private modalService: NgbModal,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.user.subscribe(pro => {
            this.user = pro;
            this.configStep = pro.config_step;
            this.user.Team = pro.Team;
            this.avatarUrl =
                !this.user.Team || this.user.Team.avatar === null
                    ? "assets/img/logo.png"
                    : this.user.Team.avatar;
            this.checkIfMissionUrl();
            if (pro) {
                this.avatarUrl =
                    pro.Team["avatar"] !== null
                        ? pro.Team["avatar"]
                        : "assets/img/logo.png";
                this.clock_tick = Date.now();
                if (this.modalReference !== undefined) {
                    this.modalReference.close("submitted");
                }
            }
        });
    }

    addExpandClass(element: any) {
        this.updateToggleMenu(element, "showMenu");
    }

    public addActiveClass(element: any) {
        this.updateToggleMenu(element, "showSubMenu");
    }

    ngOnInit() {
        this.getCharter();
        this.innerWidth = window.innerWidth;
        this.navbarCollapseClicked.subscribe((showMiniSidebar: boolean) => {
            if (showMiniSidebar) {
                this.showMenu = "0";
                this.showSubMenu = "0";
            }
        });
    }

    updateToggleMenu(element: any, menu: string) {
        const isShowMenu: boolean = menu === "showMenu";
        if (element.title === menu) {
            isShowMenu ? (this.showMenu = "0") : (this.showSubMenu = "0");
        } else {
            isShowMenu
                ? (this.showMenu = element.title)
                : (this.showSubMenu = element.title);
            if (this.innerWidth <= 768) {
                this.openSideBar.emit(!(element.submenu.length > 0));
            } else {
                this.openSideBar.emit(false);
            }
        }
    }

    openAvatarModal(avatar) {
        this.modalReference = this.modalService.open(avatar, {centered: true});
        this.modalReference.result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    async getCharter() {
        this.charterHTML = await charterService.getCharterFromPrismic();
    }

    private checkIfMissionUrl() {
        if (!this.user.Team.mission_url) {
            this.sidebarnavItems = ROUTES.filter(
                sidebarnavItem =>
                    sidebarnavItem.title !== "Dépistage COVID en cabinet"
            );
        } else {
            this.sidebarnavItems = ROUTES.filter(
                sidebarnavItem => sidebarnavItem
            );
        }
    }

    openCharterModal(charter) {
        this.modalReference = this.modalService.open(charter, {
            centered: true,
            size: "lg"
        });
        this.modalReference.result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return "by pressing ESC";
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return "by clicking on a backdrop";
        } else {
            return `with: ${reason}`;
        }
    }

    updateTeamCharter() {
        this.authenticationService.signCharter().subscribe(
            pro => {
                this.authenticationService.userState.next(<User>pro);
                if (this.modalReference !== undefined) {
                    this.modalReference.close("submitted");
                }
                Swal(
                    "Merci d'avoir signé la charte !",
                    "<div>" +
                        '<h4>Bonne utilisation de <span class="libheros-l-p"></span> :)</h4>' +
                        "</div>",
                    "success"
                );
                this.ngZone.run(() => {
                    this.user.charter = new Date();
                });
            },
            err => {}
        );
    }
}

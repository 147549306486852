import {Component, OnInit} from "@angular/core";
import {Angulartics2GoogleAnalytics} from "angulartics2/ga";
import {Angulartics2Facebook} from "angulartics2/facebook";
import {PartnerOffersService} from "../providers/partner-offers.service";
import {Document} from "prismic-javascript/d.ts/documents";

@Component({
    selector: "app-partner-offers",
    templateUrl: "./partner-offers.component.html",
    styleUrls: ["./partner-offers.component.scss"]
})
export class PartnerOffersComponent implements OnInit {
    public adsFromPrismic: Document[] = [];
    constructor(
        public angulartics2Facebook: Angulartics2Facebook,
        public angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
        public partnerOffersService: PartnerOffersService
    ) {
        angulartics2GoogleAnalytics.startTracking();
        angulartics2Facebook.startTracking();
    }

    async ngOnInit() {
        await this.getAdsFromPrismic();
    }

    async getAdsFromPrismic(): Promise<void> {
        this.adsFromPrismic =
            await this.partnerOffersService.getPartnerOffersFromPrismic();
    }
}
